/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

$(function () {
    // Call to functions related to the display and animation of the left menu and footer
    toggleAccordeonLeftMenu();
    toggleAccordeonSubLeftMenu();
    initAccordionFooter();

    // Check if the page is already scrolled, and listen for future scrolls
    onWindowScroll();

    //Initialize swipers
    initFooterReassuranceSwiper();
    initStoreDetailSwiper();
    initPreheaderSwiper();
    initSocialSwiper();
    initItemSlider(false);

    // Close preheader button (override app behaviour for smoother transition)
    $('.close_preheader').on('click', function () {
        hideBandeau();
    });

    // Handle behaviour when closing menu or search by shade click
    $(document).on('click', '#shade.forMenu', function () {
        if ($('#account_menu').hasClass('active')) {
            toggleAccountMenu.call(document.getElementById('user_btn'));
            document.body.classList.remove('account_menu_opened');
        } else {
            document.body.classList.remove('menu_opened');
        }
    });
    $(document).on('click', '#shade.forSearch', function () {
        close_search();
    });

    if($('#searchComponent').length) {
        toggleViewMobile();
    }

    if ($("#slideCateg .swiper-slide").length) {
        slideCateg = new Swiper("#slideCateg", {
            slidesPerView: "auto",
        });
    }

    // Add loader on product's modbox submit button
    $('#item_added .form_submit .button').on('click', function () {
        if ($(this).closest('#item_added').hasClass('open')) {
            $(this).siblings('.loaders').addClass('loading');
        }
    });

    // Switch between cart and wishlist view in basket
    initToggleCartTypeBtn();

    //replace mini image with medium image in cart
    if($(".cart_product_pic").length) {
        $(".cart_product_pic img").attr("src").replace("mini", "medium");
        $(".cart_product_pic img").each(function() {
            var src = $(this).attr("src");
            src = src.replace("mini", "medium");
            $(this).attr("src", src);
        });
    }

    // TUNNEL V3 FRONT
    
    
    // tunnel V3 size modal toggle
    $('body').on('click', '.toggle_modal_actions', function () {
        $(this).parents('.product_line_basket').find('.cart_item_actions').addClass('actif');
        if (!$(this).parents('.product_line_basket').find('.cart_item_actions .titre_choices_list').length) {
            var modal_cart_item_actions = $(this).parents('.product_line_basket').find('.cart_item_actions');
            var modal_cart_size_title = $("<div class='titre_choices_list'>" + Translator.translate('modify_size') + "</div>");

            modal_cart_size_title.prependTo(modal_cart_item_actions);
        }

        if (!$('#shade').hasClass('visible')) {
            $('#shade').addClass('visible');
        }
    });

    $(window).on("removeBasketItemCustom", function (data) {
        $(this).parents('.product_line_basket').find('.cart_item_actions').removeClass('actif');
    });

    $('body').on('click', '.cart_item_actions_form .productSizeFieldset input', function () {
        setTimeout(function () {
            $('#shade').removeClass('visible');
        }, 3000);
    });

    // Moving order recap to the top of the page
    if ($('.total_produit.toggle').length && $('.total_produit_dropdown').length && $('#appContainer').length) {
        var toggler = $('.total_produit.toggle');
        var dropDwon = $('.total_produit_dropdown');
        var target = $('#appContainer');

        // Saving product count
        var nbProd = toggler.find('.nb_products');

        // Changing title of toggler
        toggler.find('label').text(Translator.translate('total_product_02')).append(nbProd);

        // Moving to the top of the page
        dropDwon.prependTo(target);
        toggler.prependTo(target);

        // Wraping in one div
        $('.total_produit.toggle, .total_produit_dropdown').wrapAll('<div class="wrap_cart_recap"></div>');

        // Moving addresse to the top of the page "livraison"
        if ($('#blocs_address').length) {
            $('#blocs_address').insertAfter($('.wrap_cart_recap'));
        }

        // Moving addresse to the top of the page "paiement"
        if ($('.wrapper_payment').length) {
            $('.wrapper_payment').insertAfter($('.wrap_cart_recap'));
        }
    }

    // Moving card owner name next to card logo on the page "paiement"
    if ($('body').hasClass('checkout') && $('.alias_list .alias').length) {
        $('.alias_list .alias').each(function () {
            var cardHolder = $(this).find('.card_numbers');

            $(this).find('.card_type').append(cardHolder);
        });
    }

    // Toggle product details in cart right column
    $(".total_produit.toggle").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    if ($('.body_login.cart .login_bloc .login_form_wrapper #subtitleLogin').length) {
        var newlogin_txt_3 = Translator.translate('newlogin_txt_3');
        
        $(".body_login.cart .login_bloc .login_form_wrapper #subtitleLogin").html(newlogin_txt_3);
    }

    if ($('.cart .wrapper_panier .basket_gondole').length) {
        $('.cart .wrapper_panier .basket_gondole').appendTo('#contents_wrapper');
    }

    if ($('.wrapper_panier .product_sfs_warning_txt').length) {
        $('.wrapper_panier .product_sfs_warning_txt').prependTo('.left_side');
    }

    // Tunnel V3 delivery choice: execute chooseTransporteur function on page load in case default selected delivery mode is relay or store
    if ($('.active .choix_delivery').length > 0 && $('#delivery_address_recap_other').css('display') === 'none') {
        $('.active .choix_delivery').chooseTransporteur();
    }

    // TUNNEL V3 FRONT - END

    // Replace Un Rien C'est Tout (URCT) donation product URL (empty) with basket URL
    // If adding more products, do the same for each product ID in $arrayProductDonation in bloc_dons.php
    $("[data-product-id='2087']").find($('a')).attr("href", create_link('order_basket'));

    // Move CGV table to correct position
    moveTablePos();

    // cookies.php : Allow cookies lightbox to be reopened after being closed once
    $(".cookieShowLightbox").on('click tap', function () {
        cookies_bloc_lightbox.css('display', 'block');
    });

    // Handle case where preheader exists on desktop but not mobile, and mobile thinks there is one (body has .hasBando anyway)
    if (!$('.bandeauMenuAffix').length && $('body').hasClass('hasBando')) {
        $('body').removeClass('hasBando');
    }

    if ($('#div-worldline-hosted-tokenization iframe').length) {
        const iFrameResizer0 = $('#div-worldline-hosted-tokenization iframe')[0];
        let previousHeight = iFrameResizer0.offsetHeight;
    
        // Function to update the height
        function updateHeight(newHeight) {
            const adjustedHeight = newHeight + 15;
            $(iFrameResizer0).css('height', adjustedHeight + 'px');
            previousHeight = adjustedHeight;
        }
    
        // Check if ResizeObserver is supported
        if (window.ResizeObserver) {
            // Créer un ResizeObserver
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const currentHeight = entry.contentRect.height;
                    if (currentHeight !== previousHeight) {
                        updateHeight(currentHeight);
                    }
                }
            });
    
            // Observe the iframe
            resizeObserver.observe(iFrameResizer0);
        } else {
            // Fallback if ResizeObserver is not supported
            console.warn("ResizeObserver n'est pas pris en charge. Utilisation de la méthode de vérification périodique.");
            
            function checkHeight() {
                let currentHeight = iFrameResizer0.offsetHeight;
                if (currentHeight !== previousHeight) {
                    updateHeight(currentHeight);
                }
            }
    
            // Check every second (adjust as needed)
            setInterval(checkHeight, 1000);
        }
    }

    if ($('[data-payment-mode="paypal_worldline"]').length) {
        $('[data-payment-mode="paypal_worldline"]').on('click', function () {
            $('.paypal_worldline').click();
        });
    }

    if ($('[data-payment-mode="bancontact_worldline"]').length) {
        $('[data-payment-mode="bancontact_worldline"]').on('click', function () {
            $('.bancontact_worldline').click();
        });
    }

    // Needed to close the size modification lightbox after a new size is selected
    $(window).on("removeBasketItemCustom", function (data) {
        $('.cart_item_actions_close.close').click();
    });

    /**
     * Load global .item_container slider initialization on window scroll
     */
    window.addEventListener("scroll", function () {
        initItemSlider(false);
    });

    if ($('body').hasClass('wishlist') && $('.wrapper_wish_product').length > 0) {
        $('.wrapper_wish_product').each(function () {
            if ($(this).find('div#bloc_add_alert').length > 0) {
                $(this).find('.infos .infos_wrapper').css('opacity', '0.6');
                $(this).find('.photo_product').css('opacity', '0.6');
            }
        });
    }

    if ($('.article_cta_wrapper').length > 0) {
        $(window).on('scroll', function () {
            var wishlist_article_cta_wrapper = $('.article_cta_wrapper');
            var lastCartProductLine = $('.cart_product_line').last();

            if (wishlist_article_cta_wrapper.length && lastCartProductLine.length) {
                var wrapperBottom = wishlist_article_cta_wrapper.offset().top + wishlist_article_cta_wrapper.outerHeight();
                var lastProductLineBottom = lastCartProductLine.offset().top + lastCartProductLine.outerHeight() - 500;
                var scrollTop = $(window).scrollTop();

                if (scrollTop < wrapperBottom && scrollTop < lastProductLineBottom) {
                    wishlist_article_cta_wrapper.addClass('fixed');
                } else {
                    wishlist_article_cta_wrapper.removeClass('fixed');
                }
            }
        });
    }
});

/*
Init item slider on search page
*/
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
    toggleViewMobileSearch();
}

/*
display and animation of menu categories
*/
function toggleAccordeonLeftMenu() {
    $(document).on('click tap touch', '.menu_part.bottom .first_level_item', function () {
        var $currentMenuItem = $(this).closest('.main_menu_itm');

        if ($currentMenuItem.hasClass('active')) {
            $currentMenuItem.removeClass('active');
            $currentMenuItem.find('.submenu_wrapper').slideUp();
        } else {
            // Close all other menus
            $('.main_menu_itm').not($currentMenuItem).removeClass('active');
            $('.submenu_wrapper').not($currentMenuItem.find('.submenu_wrapper')).slideUp();

            // Open the clicked menu
            $currentMenuItem.addClass('active');
            $currentMenuItem.find('.submenu_wrapper').slideDown();
        }
    });
}

/*
display and animation of menu subcategories
*/
function toggleAccordeonSubLeftMenu() {
    $(document).on('click tap touch', '.menu_part.bottom .menu_subcategory', function () {
        var $currentSubMenuItem = $(this);
        var $currentObjetCat = $currentSubMenuItem.find('.objet_cat');

        $('.menu_part.bottom .menu_subcategory').not($currentSubMenuItem).removeClass('sub_menu_open').find('.objet_cat').slideUp();

        if ($currentSubMenuItem.hasClass('sub_menu_open')) {
            $currentSubMenuItem.removeClass('sub_menu_open');
            $currentObjetCat.slideUp();
        } else {
            $currentSubMenuItem.addClass('sub_menu_open');
            $currentObjetCat.slideDown();
        }
    });
}

// Handle header and body height after closing preheader
function hideBandeau() {
    var headerDelivery = $(".bandeauMenuAffix");

    cookieHeaderDelivery();
    $('body').removeClass('bandeauDelivery');
    $('body').removeClass('hasBando');
    headerDelivery.slideUp(300);
    if (typeof resizeAfterHideBanner == 'function') {
        resizeAfterHideBanner();
    }
}

function resizeAfterHideBanner() {
    $('header.banner_wrapper').css('top', 0);
    $('#left_menu.drawer.left').css('z-index', '120');
    $('.ariane_wrapper').css('margin-top', '0');
}

//Add scrolled class to body when scroll is detected
//header_transition class is to animate the header when the preheader is still visible (but hidden when scrolling)
function onWindowScroll() {
    var pageBody = document.body;

    if (window.scrollY > (window.innerHeight / 10)) {
        $('body').addClass("scrolled");
    }

    window.onscroll = function (e) {
        if (window.scrollY > (window.innerHeight / 10)) {
            pageBody.classList.add("scrolled");
        } else {
            pageBody.classList.remove("scrolled");
        }
    };
}

// Open and close search
/**
 * Search management
 */
function show_search() {
    var search_home = document.getElementById("search_homeV2");
    var top_search = document.getElementById("top_searchV2");
    var shade = document.getElementById("shade");

    document.body.classList.add("header_transition", "search_opened");

    // Check if elements exist
    if (!search_home || !top_search || !site_head || !shade) {
        console.error('One or more elements could not be found');
        return;
    }

    // Close menu if it was open
    if ($('body').hasClass('menu_opened')) {
        toggleMenu('left', 'left');
    }

    // Close account menu if it was open
    if ($('#account_menu').hasClass('active')) {
        toggleAccountMenu.call(document.getElementById('user_btn'));
    }

    if (top_search.classList.contains("opened")) {
        close_search();

    } else {
        search_home.focus();
        top_search.classList.add("opened");
        $('#top_searchV2').slideDown(300);
        shade.classList.add("visible", "forSearch");
        document.body.classList.add("noscroll");
        
        if (search_home) {
            search_home.addEventListener('keydown', (e) => {
                if (e.key == 'Enter') {
                    close_search();
                }
            });
        }
    }
}

function close_search() {
    var top_search = document.getElementById("top_searchV2");
    var shade = document.getElementById("shade");

    top_search.classList.remove("opened");

    if (shade.classList.contains("forSearch") && !shade.classList.contains("forMenu")) {
        shade.classList.remove("visible", "forSearch");
        setTimeout(() => {
            document.body.classList.remove("header_transition");
        }, 300);
        document.body.classList.remove("noscroll", "search_opened");
    } else {
        shade.classList.remove("forSearch");
    }
}

// Manage search bar when menu is toggled
function toggleMenuExtra() {

    if ($('#left_menu').hasClass('open')) {
        document.body.classList.add('menu_opened');

        if ($('#account_menu').hasClass('active')) {
            toggleAccountMenu.call(document.getElementById('user_btn'));
        }
        if ($('#top_searchV2').hasClass('opened')) {
            close_search();
        }

        $("#shade").addClass("visible forMenu");
        $("body").addClass("noscroll");
    } else {
        document.body.classList.remove('menu_opened');
    }

    // Close filters on shad click
    if($('#filters_menu').hasClass('open')) {
        $('#shade.forFilters').on('click touch', function() {
            $('#filters_menu').removeClass('open');
        });
    }
};

// Manage main menu and search when account menu is toggled
function toggleAccountMenuExtra() {

    if ($('#account_menu').hasClass('active')) {
        document.body.classList.add('account_menu_opened');

        if ($('body').hasClass('menu_opened')) {
            toggleMenu('left', 'left');
        }
        if ($('#top_searchV2').hasClass('opened')) {
            close_search();
        }
        if($('.modbox').hasClass('open')) {
            $('.modbox').removeClass('open');
        }

        $("#shade").addClass("visible forMenu");
        $("body").addClass("noscroll");
    } else {
        document.body.classList.remove('account_menu_opened');
    }
}

var bloc_prod_xhr;
var defaultNbProd = parseInt($('#nb_prod').val());

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = form.getValue("type"),
        idObj = idObj || $('#idObj').val(),
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        startPage = parseInt($('#page_start').val()),
        is_from_page = false,
        output = {};

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    output = triggerFilter(page, type, idObj, nb_product, "filters_form", fromPagination);

    //Handle pagination with push images
    if (fromPagination) {
        output.page = $('.item:not(.push)').length;
        output.handle_push = 'false';
    }

    nb_product = nb_product || parseFloat($('#nb_prod').val());

    let one_product = $("#oneProduct").hasClass("actif") ? true : false;

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        // Get all current item length (because of push)
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // If price exist
    if ($("input[name=price_min]").length > 0) {
        // Retrieval of the price
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {
            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                let nbProductLabel = (itm_length > 1 ? Translator.translate('2_product') : Translator.translate('one_product'));
                $('.seo_nb_product').html(itm_length + ' ' + nbProductLabel);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //The opposite for the bottom
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: ($(window).scrollTop() + 10)
                    }, 200);
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#page_start").val(startPage + 1);

                if ($("#page_start").val() >= 1) {
                    $("#initial_page").val(page);
                }
            }

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .item_container:not(.push)').length;
            var percentLoaded = (productLoaded * 100 / parseInt(output.nb_tot_prod));

            if (productLoaded > output.nb_tot_prod) {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + productLoaded);
            } else {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + parseInt(output.nb_tot_prod));
            }

            $('.txt_bandeau label').html('(' + output.nb_tot_prod + ' ' + Translator.translate('2_product') + ' )');

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            $('.loader_scroll').removeClass('loading');

            if (productLoaded >= document.getElementById('totalElems').value) {
                $('.loader_scroll').hide();
            }
        }
    });
}

function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop, type, idObj) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    form = Array.prototype.slice.call(form.elements, 0);

    form.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');

    if (pattern.test(url)) {
        var res = url.match(pattern);
        output.tri = res[1][0];
    }
    // Dtalayer V3 filter_interaction event
    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
        DataLayerTrigger.filerInteractionSearchMobile(output);
    }
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // If the price exists
    if ($("input[name=price_min]").length > 0) {
        // Prize recovery
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").css("display", "none");
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    // The opposite for the bottom
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) { }
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) { }
                }

                /** END LEGACY **/

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }

                if (typeof generateNewBlocSearchExtra === 'function') {
                    generateNewBlocSearchExtra();
                }
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            setTimeout(function () {

                var loader = $('.button + .loader');

                if (loader.hasClass('loading')) {

                    loader.removeClass('loading');
                } else {

                    loader.parent().removeClass('loading');
                }
                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');
            }

            if (!fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(1);
            } else if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
            }

        }
    });
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        value = getInputVal.bind(this),
        output = {},
        tmpoutput = {},
        is_from_page = false,
        key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if ($(".filter_date").length > 0) {

            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof (output['date']) == 'undefined') {
                output.date = 4;
            }
            else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        }
        else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {

            this.nb_prod.value = nb_product;
        } else {

            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;
        output.current_page = page + 1;

    }

    /** LEGACY **/

    // Recovery of the sort by price (descending or ascending) which unfortunately goes through the ur
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // If there is an order in the prices we load the id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        var tri = res[1][0];
    }

    /** END LEGACY **/

    if (!tri) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {
            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Number of products */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof (output['date']) == 'undefined') {
                    output.date = 4;
                }
                else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            }
            else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var new_elm = document.createElement('div'),
                        old_elm = document.getElementById('scroll_items'),
                        content, itm_length;

                    res = res.split('><><');

                    content = res[0].trim();

                    if (res[1] !== '') {
                        itm_length = parseFloat(res[1]);

                        // Allows you to update when applying a filter, the total number of products remaining after application
                        // Info updated at the top of the filters_menu - potentially in display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;

                        if (window.lazyload) {

                            lazyload.init();
                        }
                    }

                    $('#is_loading').val('0');
                },
                complete: function () {
                    var evt = new CustomEvent("generateNewBlocFilter_complete");
                    window.dispatchEvent(evt);
                }
            });
        }

    });

}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    /**
     * Updating the origin page values for infinite scrolling
     */

    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type === 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {
    var form = document.getElementById('filters_form'),
        total_element = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pager_wrapper'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $('#scroll_items .item_container:not(.push)').length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {

                if (see_all === 0) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_more_products') + '</div>';
                } else if (see_all === 1) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });

            if (productLoaded == total_element) {
                $('.see_all_product').hide();
            }
        }
    });
}

function showPopinColorSize(type) {
    var size_selected_select_option = $(this).find("option:checked").attr("value");
    var size_selected_popup_size = $(this).parents(".displayTwo").find(".achat_express.size .rollover_left .form_itm.size");

    $("#shade").addClass("visible isExpress");
    $(".rollover.achat_express." + type).show();
    $(".rollover.achat_express." + type).find(".ligne_form").addClass('actif');
    $('body').addClass('noscroll');

    if ($(this).hasClass("size")) {
        $(this).blur();
    } else {
        $(this).find('#prod_couleur').blur();
    }

    size_selected_popup_size.each(function () {
        if ($(this).attr("data-sizeid") === size_selected_select_option) {
            size_selected_popup_size.find("label").removeClass("selected");
            $(this).find("label").addClass("selected");
        }
    });
}
// Animate footer accordion
function initAccordionFooter() {

    $('.footer_accordion .col').each(function () {
        $('<div/>', {
            class: 'accordion_items'
        }).appendTo(this);
        $(this).find('.col_links').appendTo($(this).find('.accordion_items'));
    });

    $(document).on('click tap touch', '.footer_accordion .col_title', function () {
        var $currentFooterItem = $(this).closest('.col');

        if ($currentFooterItem.hasClass('active')) {
            $currentFooterItem.removeClass('active');
            $currentFooterItem.find('.accordion_items').slideUp();
        } else {
            // Close all other accordions
            $('.col').not($currentFooterItem).removeClass('active');
            $('.accordion_items').not($currentFooterItem.find('.accordion_items')).slideUp();

            // Open the clicked accordion
            $currentFooterItem.addClass('active');
            $currentFooterItem.find('.accordion_items').slideDown();
        }
    });
}

// Social module swiper
function initSocialSwiper() {
    $('.social-module .home-module-body').addClass('swiper-container');
    $('.social-module .social-media-container').addClass('swiper-wrapper');
    $('.social-module .social-media-post').addClass('swiper-slide');
    var socialSwiperOffset = $('body').hasClass('homepage') ? 10 : 0;
    var socialSwiper = new Swiper('.social-module .home-module-body', {
        slidesPerView: 2.75,
        freeMode: true,
        spaceBetween: 10,
        slidesOffsetBefore: socialSwiperOffset,
        slidesOffsetAfter: socialSwiperOffset,
    });
}

// Footer reassurance swiper
function initFooterReassuranceSwiper() {
    if ($('.reassurance-swiper').length) {
        var footerReassuranceSlider = new Swiper('.reassurance-swiper', {
            slidesPerView: 1,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            autoplay: 3000,
            speed: 600,
        });
    }
}

// Store detail swiper
function initStoreDetailSwiper() {
    if($('#store_slider .swiper-slide').length > 1) {
        var storeDetailSlider = new Swiper('#store_slider', {
            slidesPerView: 1,
            paginationClickable: true,
            autoplay: 3000,
            speed: 600,
        });
    }
}

// Preheader swiper
function initPreheaderSwiper() {
    if ($("#preheaderSlider").length) {
        if ($("#preheaderSlider .swiper-wrapper .swiper-slide").length > 1) {
            setTimeout(function () {
                new Swiper("#preheaderSlider", {
                    loop: true,
                    autoplay: 3000,
                    speed: 2200,
                    effect: "slide",
                    autoplayDisableOnInteraction: false,
                    direction: "vertical",
                });
            }, 800);
        } else {
            new Swiper("#preheaderSlider", {
                slidesPerView: 1,
            });
        }
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    var has_errors = false;

    $(formId + " .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $(formId).serialize();
        data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}
function changeColorMobile() {
    let color_Id = $(this).data('colorid');
    let color_popup = $('.rollover.achat_express.color .choices_list .form_itm');
    let produitId = this.getAttribute('data-produitid');
    let selectElement = document.getElementById('prod_couleur');
    let options = selectElement.options;

    $("#prod_couleur").val(color_Id);
    loadProduct.call(this, 'couleur');
    color_popup.removeClass("active selected");
    $(this).addClass("active selected");
    $('body').removeClass('noscroll');

    setTimeout(function () {
        $("#shade").removeClass('visible');
    }, 500);
}

function changeSizeMobile(sizeGrouped) {
    let sizeId = $(this).data('sizeid');
    let productId = $(this).data('produitid');
    let size_popup = $('.rollover.achat_express.size .choices_list .form_itm');

    $("#prod_taille").val(sizeId);
    size_popup.removeClass("active");
    $(this).addClass("active");

    if ($("#prod_taille").hasClass("error")) {
        $("#prod_taille").removeClass("error");
    }

    if (sizeGrouped) {
        loadProduct.call(this, 'taille');
    } else {
        onAttrSelect(parseFloat(productId));
    }

    $("#button_add, #button_add_float").addClass("active");

    if ($('#prod_taille').length > 0) {
        if ($('#prod_taille option:selected').hasClass('disabled')) {
            $('#prod_taille').addClass('no_stock');
        } else {
            $('#prod_taille').removeClass('no_stock');
        }
    }

    if ($(this).hasClass('disable_globale')) {
        if ($('#button_add_float').length > 0) {
            $('#button_add_float').hide();
            $('#bloc_add_alert_float').show();
        }
    } else {
        if ($('#button_add_float').length > 0) {
            $('#button_add_float').show();
            $('#bloc_add_alert_float').hide();
        }
    }

    hidePopinColorSize();
}

function hidePopinColorSize() {
    $("#shade").removeClass("visible isExpress");
    $(".rollover.achat_express").hide();
    $(".rollover.achat_express").find(".ligne_form").removeClass('actif');
    $('body').removeClass('noscroll');
}

function openColorAchatExpressV2() {
    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');
        let move_left = $(parent_block).css('transform').split(',')[4];

        $(parent_block).css({ "left": move_left + "px" });
    }

    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');
    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');

    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');
    $("#shade").addClass("visible").addClass("isExpress");

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }

    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
    }

    $('#shade.isExpress').off('click touch').on('click touch', function() {
        lightbox.hide();
        $(this).removeClass('visible isExpress');
        $div_color.removeClass('actif');
    });
}

function hideAchatExpress() {
    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');

        $(parent_block).css({ "left": "" });
    }

    $("#shade").removeClass("visible").removeClass("isExpress");

    $(this.form).find('.achat_express').hide();

    $(this.form).find('.actif').removeClass('actif');

    $(this.form).find('.actif').removeClass('actif');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');

    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {
    if ($(".prodItemSlider, .searchItemSlider, #seoSlider").length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) {
            // Toggle view is triggerred
            setTimeout(function () {
                $(".prodItemSlider, .searchItemSlider").each(function () {
                    if ($(this).hasClass("initialized")) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else {
            var paginationElement = $('body').hasClass('product_page') ? false : '.itemSliderPagination';

            // From page load and scroll
            $(".prodItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    // Prevent initialized sliders to go back to the first frame during scroll

                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: paginationElement,
                    });

                    $(this).addClass("initialized");
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    $(this).addClass("initialized");
                }
            });

            // SEO block slider
            $("#seoSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    // Prevent initialized sliders to go back to the first frame during scroll
        
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: ".seoSliderPagination",
                    });
        
                    $(this).addClass("initialized");
                }
            });
        }
    }
}

function onLoadProductForm(hide_bookinstore, date_arrivage)
{
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    // native "ready" functions are way too slow
    var formInterval = window.setInterval(function () {

        if (isGlobalEActive || document.readyState == "complete") {
            clearInterval(formInterval);
        }

        if (isGlobalEActive) {
            data.warehouse_only = 1;
        } else if (document.readyState != "complete") {
            return false;
        }

        $.ajax({
            url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
            type: 'post',
            data: data,
            success: function (res) {
                try {
                    res = $.parseJSON(res);
                } catch (e) {
                    console.error("parseJSON");

                    return;
                }

                if (res.success && res.result) {
                    var has_stock = false;
                    var length_item_color = $('.product_form  [name="itm_colour"]').length;

                    if (length_item_color) {
                        var sizes_tab = res.result[produit_id][$("input[name=itm_colour]:checked").val()];
                        
                    } else {
                        var sizes_tab = res.result[produit_id][0];
                    }

                    var size_box, is_disabled;
                    for (size_id in sizes_tab) {
                        if (sizes_tab.hasOwnProperty(size_id)) {

                            if (sizes_tab[size_id]) {
                                has_stock = true;
                                $('#size_' + size_id).removeClass('disabled');
                            } else {
                                $('#size_' + size_id).addClass('disabled');
                            }
                        }
                    }

                    if (has_stock) {
                        $("#add_basket_wrapper").show();
                        $("#link_book_in_store").show();
                        $(".stock_check").show();
                        $("#bloc_add_alert").hide();
                    } else {
                        $("#add_basket_wrapper").hide();
                        $(".stock_check").hide();
                        if (hide_bookinstore == 1) { // if we want to hide CTA "book in store"
                            $("#link_book_in_store").hide();
                        }
                        if (date_arrivage == false || date_arrivage.length > 0 && date_arrivage != "0000-00-00 00:00:00") {
                            $("#bloc_add_alert").show();
                        }
                    }
                }
            }
        });
    }, 500);
}

/**
 * Change product display on category page
 */
function toggleViewMobile() {
    $("div.toggleNumObjects span").toggleClass("actif");
    if ($("#oneProduct").hasClass("actif")) {
        $("#twoProducts").removeClass("actif");
        $(".item_container").addClass("full");
        createCookie("nb_produits", 1, 1);
    } else {
        $(".item_container").removeClass("full");
        $("#oneProduct").removeClass("actif");
        createCookie("nb_produits", 2);
    }

    if ($(".dynasearchwrapper").length) {
        initItemSlider(true);
    } else {
        initItemSlider(false);
    }
}

/**
 *  Change product display on search page, nb_produits cookie seems to be erased before the result page is shown
 */
function toggleViewMobileSearch() {
    // Read the value of nb_produits cookie
    var nbProduits = getCookie("nb_produits");

    // Update class based on the value of the cookie
    if (nbProduits == 1) {
        $("#oneProduct").addClass("actif");
        $("#twoProducts").removeClass("actif");
        $(".item_container").addClass("full");
    } else {
        $("#oneProduct").removeClass("actif");
        $("#twoProducts").addClass("actif");
        $(".item_container").removeClass("full");
    }

    if ($(".dynasearchwrapper").length) {
        initItemSlider(true);
    } else {
        initItemSlider(false);
    }
}

function searchCategoryFilter() {
    window.setTimeout(function () {
        new Swiper("#slideCateg", {
            slidesPerView: "auto",
        });
    }, 500);
}

// FAQ
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // Only show results that match the search
            $.each( array_questions, function( i, val ) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if ( match ) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function() {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display','none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1)
    });
}

function researchFaq(node_id, level, question_id) {
    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);
}

// Use to stored the current button that has been selected
function currentSizeStorage() {
    // if input size is selected get id
    $(".form_itm_wrapper input[type='radio'].input_check.size").each(function () {
        if ($(this).is(':checked')) {
            const radioId = $(this).attr("id");
            sessionStorage.setItem('radioId', radioId);
            return false;
        }
    });
}

function initToggleCartTypeBtn() {
    if ($(".toggleCartTypeBtn").length) {
        let toggleCart = $(".toggleCartTypeBtn");
        let cartContent = $(".cart_main_table.basket");
        let wishContent = $(".cart_main_table.wishlist");
        let cartSummary = $("#tunnel_right_col");

        if ($(".toggleCartTypeBtn.wishlist").find('.nb_products').text() != 0) {
            $(".toggleCartTypeBtn.wishlist").find('.nb_products, .parenthesis').show();
        }

        toggleCart.on("click", function () {
            toggleCart.removeClass("actif");
            $(this).addClass("actif");
            $(".cart_main_table").removeClass("displayed");

            if ($(this).hasClass("basket")) {
                cartContent.addClass("displayed");
                cartSummary.removeClass("noDisplay");
            } else {
                wishContent.addClass("displayed");
                cartSummary.addClass("noDisplay");
            }
        });
    }
}

function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, prodId;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $('#cart_btn').data('cart', response);

                /**
                 * Move out of stock products to the top of the cart
                 */

                updateCartElements();
            }
        });
    }
}

// Move CGV table to correct position
function moveTablePos() {
    var $contentWrapper = $('.order_cgv.mobile .wrapper_content_sat.page_text .content_sat'),
        $table = $contentWrapper.find('.wrapperCgvTable'),
        $lastEl = $contentWrapper.find('#row_bloc_4 #bloc_4 strong').last();

    if($table.length && $lastEl.length) {
        $table.insertBefore($lastEl);
    }
}

function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("noscroll");

    if (!$("#shade").hasClass("actif")) {
        $("#shade").addClass("actif");
    }

    if (id != "lightbox_preco") {
        $("#shade").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id == "popup-fairlymade") {
        $("#shade").addClass('forFairlyMade');
    }
}

function closeMultiShad(id) {
    $("#" + id).removeClass("actif");
    $("#shade").removeClass("actif");
    $("body").removeClass("noscroll");

    if (id == "popup-fairlymade") {
        $("#shade").removeClass('forFairlyMade');
    }
}

/*
Share wishlist
*/
function sendWishListForm(evt) {
    var loadze, form, theme, to_mails, to_message, your_name, your_mail, errbox,
        output_txt;

    evt.preventDefault();

    loader = $(this.nextElementSibling);

    loader.fadeIn(200, function () {

        form = $(this).closest('form').get(0);

        to_mails = form.sendWishListToMails;
        to_message = form.sendWishListToMessage;
        your_name = form.sendWishListYourName;
        your_mail = form.sendWishListYourMail;

        errbox = document.getElementById('item_error');
        token = document.getElementById('token_security_sendwishlist').value;
        captcha = document.getElementById('g-recaptcha-response').value;

        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form'),
            type: 'get',
            data: {
                theme: theme, to_mails: to_mails.value,
                to_message: to_message.value, your_name: your_name.value,
                your_mail: your_mail.value,
                token_security: token,
                'g-recaptcha-response': captcha
            },
            success: function (res) {
                $('.inputErr', form).removeClass('inputErr');

                if (res.indexOf('ok') === 0) {
                    var s_adresse = res.substr(3);
                    var sing = (res.split(',').length > 2) ? 'sendfriend_ok_plur_mobile' : 'sendfriend_ok_sing_mobile';

                    output_txt = Translator.translate(sing, s_adresse);

                    modBoxClose.call(document.getElementById('wishlist_share'));

                } else {
                    if (!isNaN(Number(res))) {

                        switch (Number(res)) {

                            case 1:
                            case 2:
                                $(to_mails).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_mail_friends_invalid');
                                break;

                            case 3:
                                $(to_message).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_no_message');
                                break;

                            case 4:
                                $(your_name).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_no_name');
                                break;

                            case 5:
                            case 6:
                                $(your_mail).addClass('inputErr');

                            case 5:
                                output_txt = Translator.translate('sendfriend_error_no_my_mail');
                                break;

                            case 6:
                                output_txt = Translator.translate('sendfriend_error_my_mail_invalid');
                                break;
                        }
                    } else if (res == 'token_error') {
                        output_txt = Translator.translate('error_token_security');
                    } else if (Number(res.split(',')[0]) === 7) {

                        var s_adresse = res.substr(3);
                        var sing = (res.split(',').length > 2) ? 'sendfriend_no_ok_plur_mobile' : 'sendfriend_no_ok_sing_mobile';

                        output_txt = translate(sing, s_adresse);
                    }
                }

                $('.modbox_title', errbox).html('<span>' + output_txt + '</span>');

                modBoxClose.call(document.getElementById('wishlist_share'));

                modBoxOpen.call(errbox);

                loader.stop(true, true).fadeOut(200);

                $("html,body").css('position', 'initial');
            }
        });
    });
}

// Set heart icon to filled for products in wishlist
$(window).on("load", function() {
    getWishlistProducts();
});

function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0 ) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: data,
            success: function(response) {
                if (response && typeof response != 'undefined' && response.length > 0) {
                    let array_product_id = JSON.parse(response);
                    
                    for (product_wishlist in array_product_id) {
                        $('a[data-productid = "' + array_product_id[ product_wishlist ].fk_produit_id + '"]').addClass("in_wishlist"); // in_wishlist used in hesperide and maybe other sites
                        $('a[data-productid = "' + array_product_id[ product_wishlist ].fk_produit_id + '"]').addClass("existToWishlistButton");
                        $('a[data-productid = "' + array_product_id[ product_wishlist ].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                    }
                }
            }
        });
    }
}


function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event == "undefined") {
        button_add_to_wishlist_initial_click_event = $(
            "#addToWishlistButton"
        ).attr("onclick");
    }
    if (typeof button_add_to_wishlist_initial_label == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let checkColor = $(".prodColor[data-produitid='" + productId + "']");
    let checkSize = $("#prod_taille");

    let colorSelected =
        checkColor.is("input[type=radio]") && checkColor.is(":checked")
            ? checkColor.val()
            : "";
    let sizeSelected = checkSize.length ? checkSize.val() : "";

    if (
        typeof productId != "undefined" &&
        colorSelected !== "" &&
        sizeSelected !== ""
    ) {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            },
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    $("#addToWishlistButton").attr(
        "onclick",
        button_add_to_wishlist_initial_click_event
    );

    addToWishlistUpdateHeader();

    if (in_wishlist) {
        $("span", "#addToWishlistButton").html(
            Translator.translate("exist_to_wishlist_button_label")
        );
        $("#addToWishlistButton")
            .removeClass("addToWishlistButton")
            .addClass("existToWishlistButton");
    } else {
        $("span", "#addToWishlistButton").html(
            Translator.translate("wishlist")
        );
        $("#addToWishlistButton")
            .removeClass("existToWishlistButton")
            .addClass("addToWishlistButton");
    }
}

// Adds an item to the customer's wishlist
// Could use more standard methods
function addToWishlist(wishlist_checkbox) {
    var form = document.getElementById('item_add_form'),
        itm_id = (form.itm_id) ? form.itm_id.value : null,
        value = getInputVal.bind(form),
        head_offset = (window.innerWidth * 0.2921875),
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop,
        data, has_errors;

    if (!itm_id) {

        return false;
    }

    var btn = $(this);


    data = {
        mobile: 1,
        product_id: itm_id,
        size_id: value('itm_size'),
        color_id: value('itm_colour'),
        need_color_id: !!form.itm_colour,
        need_size_id: !!form.itm_size,
        is_model: value('itm_is_model')
    };

    // If we are in creation mode
    if (btn.attr('data-action') == 'create') {

        var input_create = $('input[name="wishlist_name"]');

        // If a name is entered and too short, display an error message
        if (input_create.val().length < 3) {
            input_create.addClass('inputErr');
            $('#err_create_wishlist').html(Translator.translate('err_create_wishlist_length'));

            return;
        } else {
            input_create.removeClass('inputErr');
            $('#err_create_wishlist').html('');
        }
        data.wishlist_nom = input_create.val();
    } else if (btn.attr('data-action') == 'modify') {
        data.wishlist_id = wishlist_checkbox.val();
    }

    has_errors = false;

    if ((data.need_color_id && !data.color_id) || (data.need_size_id && !data.size_id)) {

        $('html, body').animate({ scrollTop: form_offset - head_offset }, 400);

        if (!data.color_id) {

            form.itm_colour.classList.add('error');
        }

        if (!data.size_id) {

            form.itm_size.classList.add('error');
        }

        return false;
    }

    // If the checkbox is checked then the product is in the wishlist

    if (wishlist_checkbox != undefined && !wishlist_checkbox.is(':checked') || $('#addToWishlistButton').hasClass('existToWishlistButton')) {
        // Remove the product from the wishlist
        $.post(path_relative_root + 'ajax_remove_from_wishlist.php?time=' + new Date().getTime(), data, function(resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    openModalBox.call(document.getElementById('alertboxlogin'));
                } else {
                    if (resp.message != "error") {
                        $('#alert_box .alert_box_content').html(Translator.translate('product_take_off'));
                    } else {
                        $('#alert_box .alert_box_content').html(Translator.translate(resp.result.error_message));
                    }
                    openModalBox.call(document.getElementById('alert_box'));
                }
            } else {
                // Uncheck the checkbox
                $('#addToWishlistButton').removeClass("existToWishlistButton").addClass("addToWishlistButton");

                if (wishlist_checkbox !== undefined ) {
                    wishlist_checkbox.prop('checked', false);
                    wishlist_checkbox.closest('.wishlist_item').find('.wishlist_add_report').html('<span class="delete">' + Translator.translate('product_take_off') + '</span>');
                }

                var m_wishlist_size = (resp.result.wishlist_size !== undefined) ? resp.result.wishlist_size : resp.result.wishlistLength;

                // Display the wishlist in the menu and update the data for the number of added products
                $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + m_wishlist_size + ')');
            }
        });
    } else {
        $.ajax({
            url: path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(),
            type: 'post',
            data: data,
            success: function(res) {
                btn.next('.loader').removeClass('loading');
                if (res.error) {

                    if (res.message == "login_required") {

                        modBoxOpen.call(document.getElementById('alertboxlogin'));
                    } else if (res.message != "error") {

                        $('#alert_box .alert_box_content').html(Translator.translate('add_wishlist_error_'+res.message));
                        modBoxOpen.call(document.getElementById('alert_box'));
                    } else {

                        $('#alert_box .alert_box_content').html(Translator.translate(res.result.error_message));
                        modBoxOpen.call(document.getElementById('alert_box'));
                    }
                } else {
                    //DataLayer event
                    if (window.dataLayer !== undefined) {
                        window.dataLayer.push({
                            'event': 'addToWishlist',
                            'products': {
                                'product_id': value('itm_id'),
                                'product_ref': value('itm_ref'),
                            }
                        });
                    }

                    var dl_v3_obj = res.result.dlv3_wishlist_item;

                    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                        DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                    }

                    // Creation case
                    if (btn.attr('data-action') == 'create') {
                        res.result.checked = true;
                        buildWhislitList(res.result);
                    } else if (data.wishlist_id != null) {
                        // Show that the product has been added
                        $('#wishlist_' + data.wishlist_id).closest('.wishlist_item').find('.wishlist_add_report').html('<span class="success">' + translate('product_add') + '</span>');
                    } else {
                        $("#addToWishlistButton")
                            .removeClass("addToWishlistButton").addClass("existToWishlistButton")
                        $("#addToWishlistButtonTxt")
                            .html(Translator.translate('exist_to_wishlist_button_label'))
                    }
                    
                    var m_wishlist_size = (res.result.wishlist_size !== undefined) ? res.result.wishlist_size : res.result.wishlistLength;

                    // MOBILE - update wishlist item count
                    $('ul.list_menu #menu_account_wishlist').css('display', 'block');
                    $('ul.list_menu #menu_account_wishlist span.highlight').text('(' + m_wishlist_size + ')');
                }
            }
        });
    }
}

// Add specific offered product to cart
function add_produit_specifique() {
    let form = document.querySelector('#lightbox_achat_express .express_response form');
    let product_id = form.getValue('itm_id');
    let color_id = form.getValue('itm_colour');
    let size_id = form.getValue('itm_size');
    let retour = ajax_file(path_relative_root + 'ajax_add_produit_specifique.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id);

    if (retour) {
        location.href = path_relative_root + create_link('order_basket');
    }
}

// override app function to fit up to date version of cart product changes modal management
function hideModalAction(evt) {
    var shade = $('#shade');
    var modal = $(".cart_item_actions");

    if (typeof evt !== "undefined") evt.preventDefault();
    shade.off('touchend', modBoxClose).removeClass('visible');
    modal.removeClass('actif').fadeOut('fast');
    $(document).find('.wrapper_cart_product_desc').find('.toggle_modal_actions').fadeIn('fast');
}

function loadWishlistDataForAlertStock(element) {
    var $element = $(element);

    // Form checked by lightboxAlertStock
    var $form = $('#product_selects');
    $form.find('input[name="tailleProd"]').val($element.data('size-id'));
    $form.find('input[name="couleurProd"]').val($element.data('color-id'));

    lightboxAlertStock(null, 'stock', $element.data('id'));
}
