/* STORE LOCATOR */
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        var onclickGeo = `onclick="geolocGoto(${lat}, ${lng})";`;
    } else { 
        var onclickGeo = "";
    }

    var ligneListe =
        `<div class="elem_list_contents" ${onclickGeo}> <div class="nom_store">`;
    
    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse +
        "</span>" +
    '<span class="ville_store">' +
        " - " +
        cp +
        " " +
        ville +
        " " +
        '<span class="pays_store">' +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }

    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a></div></div>";

    if (!is_page_product) {
        ligneListe +=
            '<div class="store_more"><a onclick="createDataLayer(' +
            id +
            ')" href="' +
            path_relative_root +
            url +
            '">' +
            Translator.translate("afficher_horaires") +
            "</a>" +
            "</div>";
    }

    ligneListe += "</div>";
    li.innerHTML = ligneListe;
    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(38, 45),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/indic_carte_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );

    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    var cpt_recherche = typeof cpt != "undefined" ? "(" + cpt + ") - " : "";

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span></div>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse +
        " - " +
        cp +
        " " +
        ville +
        "<br />" +
        pays +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;
    
    // Add marker image inside tooltip
    ligneListe += `<div class="store_marker"><img src="${image.url}"/></div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;
    markersArray.push(marqueur);

    return marqueur;
}

// Search of diffrent shop depending on the filter
function rechercheProximite(latlng, not_init) {
    // for the filter
    var param = '';
    // for the search
    var param_sup = '';

    // Owe get the checkbox for the filter
    $("input[name='filtres[]']:checked").each(function () {
        param += '&filtres[]=' + $(this).val();
    });

    var adresse = document.getElementById('search_cp').value;

    if (adresse.substr(0, 20) === Translator.translate('default_address_begin')) {
        adresse = '';
    }

    param_sup += 'search=' + adresse + param;

    if (!($('body.product_page.mobile').length && removePaysRequest)) {
        param_sup += '&pays=' + encodeURIComponent(document.getElementById('search_pays').value);
        param_sup += '&pays_id=' + $("#search_pays").find(":selected").data('id');
    }

    if (document.getElementById('pdv') && document.getElementById('pdv').value !== '') {
        param_sup += '&pdv=' + encodeURIComponent(document.getElementById('pdv').value);
    }

    if (document.getElementById('produit_id') && document.getElementById('produit_id').value !== '') {
        param += '&produit_id=' + encodeURIComponent(document.getElementById('produit_id').value);
        param_sup += '&produit_id=' + encodeURIComponent(document.getElementById('produit_id').value);
    }

    // specific case catalog
    if (document.getElementById('cataloguesWrapper') && typeof(magasin_filtre_nom) && magasin_filtre_nom!='') {
        param += '&magasin_filtre_nom=' + magasin_filtre_nom;
        param_sup += '&magasin_filtre_nom=' + magasin_filtre_nom;

    } else {
        if (document.getElementById('magasin_filtre_nom')) {
            if (document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom')) {
                param += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom');
                param_sup += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom');
            } else {
                param += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').value;
                param_sup += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').value;
            }
        }
    }

    type_call = 'auto';

    var lat_exact;
    var lng_exact;
    var requeteRecherche;
    var retailers_map = $('#retailers_map');

    retailers_map.css('width', '100%');

    if (typeof (latlng) != 'undefined') {
        type_call = 'button';

        if (latlng.coords || (latlng.lat && latlng.lng)) {
            if (retailers_map.length) {
                var myOptions = {
                    styles: google_map_style
                };

                var mcOptions = {
                    gridSize: 80,
                    imagePath: path_relative_root + 'img/maps/cluster'
                };

                if (typeof MapsProviderService != "undefined") {
                    var mapHtml = document.getElementById('retailers_map');
                    maCarte = MapsProviderService.initSearchShopPage(mapHtml);
                } else {
                    maCarte = new google.maps.Map(document.getElementById("retailers_map"), myOptions);
                    clusterer = new MarkerClusterer(maCarte, markers, mcOptions);
                }
                
                boundChanched(maCarte, markersArray);
                markers = [];
            }

            if (latlng.coords) {
                lat_exact = latlng.coords.latitude;
                lng_exact = latlng.coords.longitude;
            } else {
                lat_exact = latlng.lat;
                lng_exact = latlng.lng;
            }

            param_sup = param;
            param_sup += '&pays=' + encodeURIComponent(document.getElementById('search_pays').value);
            param_sup += '&pays_id=' + $("#search_pays").find(":selected").data('id');

            var requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?lat=' + lat_exact + '&lng=' + lng_exact + '&' + param_sup;
        } else {
            requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?lat=' + latlng.geometry.location.lat() + '&lng=' + latlng.geometry.location.lng() + '&' + param_sup;
        }

    } else {
        requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?' + param_sup;
    }

    no_result = false;
    // to load only once
    horaire_txt = Translator.translate('horaires_map');
    close_txt = Translator.translate('close2');
    // we execute the search depending on the filter
    if (typeof not_init == undefined || !not_init) {
        initSearch();
    }

    downloadUrl(requeteRecherche, '100km', type_call, function (doc) {
        var xml = doc.responseXML;
        var marqueurs = xml.documentElement.getElementsByTagName("marqueur");
        clearOverlays();
        var listeLaterale = document.getElementById('list-store');
        
        if (document.getElementById('search_cp').value !== '') {
            listeLaterale.innerHTML = '';
        }

        if (marqueurs.length === 0) {
            $(".search-result").hide();
            displayLoader('show');
            listeLaterale.innerHTML = '<span class="no_result">' + Translator.translate('txt_no_near_shop') + '</span>';

            if (maCarte) {

                var latlng = new google.maps.LatLng(46.864716, 2.22);
                maCarte.setCenter(latlng);
                maCarte.setZoom(5);
            }

            // If we make a search we display th list
            if (type_call == 'button') {
                // If no result we display the map on initial position
                displayLoader('show');
                $('#store-nbr').html('');
                document.getElementById('list-store').innerHTML = '<span class="no_result">' + Translator.translate('txt_no_near_shop') + '</span>';
                $('.section-list-store').show();
            }
            return;
        } else if (type_call === 'button') {
            $('.section-list-store').show();

            if($('.wrapper_retailers_map').length && !$('.product_page.mobile').length) {
                window.scrollTo(0, ($('.wrapper_retailers_map').offset().top - $("#site_head_wrap").height() ))
            }
        }

        var query = '';

        if ($("#search_cp").val()) {
            query = '?search_cp=' + encodeURI($("#search_cp").val());
        }

        if (typeof lat_exact !== 'undefined' && typeof lng_exact !== 'undefined') {
            if (query != '') {
                query += '&';
            } else {
                query = '?';
            }
            query += 'lat=' + lat_exact + '&lng=' + lng_exact;
        }

        $(".search-result").show();

        // We create markers depending on the address
        var rectangleMarqueurs = new google.maps.LatLngBounds();
        // We check if the store is still open depending on user time
        var date = new Date();
        var time = (date.getHours() < 10 ? '0' : '') + date.getHours();
        time += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        
        for (var i = 0; i < marqueurs.length; i++) {
            var nom = marqueurs[i].getAttribute('nom');
            var adresse = marqueurs[i].getAttribute('adresse');
            var ville = marqueurs[i].getAttribute('ville');
            var cp = marqueurs[i].getAttribute('cp');
            var pays = marqueurs[i].getAttribute('pays');
            var magasinId = marqueurs[i].getAttribute('magasin_id');
            var stock = marqueurs[i].getAttribute('stock');
            var stock_quantity = marqueurs[i].getAttribute('stock_quantity');
            var stockStatus = marqueurs[i].getAttribute('stock-status');
            var clientId = marqueurs[i].getAttribute('client_id');
            var favorite = marqueurs[i].getAttribute('favorite');
            var beContacted = marqueurs[i].getAttribute('contact_by_store');
            var picture_filtre = new Array();

            if (marqueurs[i].hasChildNodes()) {
                var filters = marqueurs[i].childNodes;
                for (var j = 0; j < filters.length; j++) {
                    picture_filtre.push(filters[j].getAttribute('picture_filtre'));
                }
            }

            if (cp.length === 4 && pays === 'France') {
                cp = '0' + cp;
            }

            var id = marqueurs[i].getAttribute('magasin_id');
            var image = marqueurs[i].getAttribute('image');
            var horaire = marqueurs[i].getAttribute('horaire');
            var horaire_open_pause = (marqueurs[i].getAttribute('horaire_open_pause') != null ? marqueurs[i].getAttribute('horaire_open_pause') : '');
            var horaire_close_pause = (marqueurs[i].getAttribute('horaire_close_pause') != null ? marqueurs[i].getAttribute('horaire_close_pause') : '');
            var horaire_open = 0;
            var horaire_close = 0;
            var horaire_close_pause_string = '';
            var horaire_open_pause_string = '';

            if (marqueurs[i].getAttribute('horaire_open') != null && marqueurs[i].getAttribute('horaire_open') != '') {
                horaire_open = marqueurs[i].getAttribute('horaire_open').replace(/:/g, '');
                horaire_close = marqueurs[i].getAttribute('horaire_close').replace(/:/g, '');
                var horaire_open_string = marqueurs[i].getAttribute('horaire_open').replace(":", "h");
                var horaire_close_string = marqueurs[i].getAttribute('horaire_close').replace(":", "h");

                if (horaire_close_pause !== "" && horaire_open_pause !== "") {
                    horaire_close_pause = marqueurs[i].getAttribute("horaire_close_pause").replace(/:/g, '');
                    horaire_open_pause = marqueurs[i].getAttribute("horaire_open_pause").replace(/:/g, '');
                    horaire_close_pause_string = marqueurs[i].getAttribute('horaire_close_pause').replace(":", "h");
                    horaire_open_pause_string = marqueurs[i].getAttribute('horaire_open_pause').replace(":", "h");
                }
            }

            var currently_open = 0;

            if (parseInt(horaire_open) < parseInt(time) && parseInt(time) < parseInt(horaire_close)) {
                currently_open = 1;
            }
            
            var horaire_today = marqueurs[i].getAttribute('horaire_today');
            
            // if the store is closed
            if (!horaire) {
                horaire_today = "";
            } else if (horaire && !currently_open) {
                horaire_today = close_txt;

            } else {
                if (horaire_open_string && horaire_close_string) {
                    if (horaire_close_pause !== '' && horaire_open_pause !== '') {
                        horaire_today = store_open_until_3a + horaire_open_string + store_open_until_3b + horaire_close_pause_string + store_open_until_3d +
                        horaire_open_pause_string + store_open_until_3b + horaire_close_string + store_open_until_3c;
                    } else {
                        horaire_today = store_open_until_2.replace(/\%s/, horaire_close_string);
                    }
                }
            }

            var horaire_plus = marqueurs[i].getAttribute('horaire_plus');
            
            if (horaire_plus) {
                horaire += '<span class="horaire_plus" style="display: none;">' + horaire_plus + '</span>';
            }

            if (marqueurs[i].getAttribute('holiday') !== '') {
                horaire += '<br>' + marqueurs[i].getAttribute('holiday');
            }
                
            var url = marqueurs[i].getAttribute("url");
            var telephone = marqueurs[i].getAttribute('tel');
            var email = marqueurs[i].getAttribute('email');
            var site = marqueurs[i].getAttribute('site');
            var magasin_type = marqueurs[i].getAttribute('magasin_type');
            var flag_available = marqueurs[i].getAttribute('flag_available');
            var lat = parseFloat(marqueurs[i].getAttribute("lat"));
            var lng = parseFloat(marqueurs[i].getAttribute("lng"));
            var distance = 0;

            if (marqueurs[i].getAttribute('distance')) {
                var distance = parseFloat(marqueurs[i].getAttribute('distance'));
            }

            // Filter shop
            var shop_filter = "";

            if (marqueurs[i].getAttribute("filtres").length) {
                shop_filter = marqueurs[i].getAttribute("filtres");
            }

            var horaire_formatted = marqueurs[i].getAttribute("horaire_formatted");
            
            if (horaire_formatted != "") {
                horaire_today = horaire_formatted;
            }

            var point = new google.maps.LatLng(parseFloat(marqueurs[i].getAttribute('lat')), parseFloat(marqueurs[i].getAttribute('lng')));
            var marqueur = creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image, magasin_type, (i + 1), id, url, distance, currently_open, horaire_today); 
            var nouvelleLigneListe = creerLigneListe((i + 1), id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng, horaire_plus);
            listeLaterale.appendChild(nouvelleLigneListe);
            markers.push(marqueur);
            rectangleMarqueurs.extend(point);

            if ($('#pdvBox').is(":visible")) {
                $('#pdvBox').addClass('deployed');
                setTimeout(function () {
                    $(".store-container").overlayScrollbars({ });
                }, 250);
            }
        }

        if (document.querySelector("body.mobile .elem_list_contents")) {
            showStoreAll();
        }

        $('#store-nbr').html(marqueurs.length + ' ' + Translator.translate('recherche_magasins'));

        if (maCarte) {
            if ($("#retailers_map").length) {
                var myOptions = {
                    styles: google_map_style
                };

                var mcOptions = {
                    gridSize: 80,
                    imagePath: path_relative_root + 'img/maps/icns/cluster_0'
                };

                if (typeof MapsProviderService != "undefined") {
                    var mapHtml = document.getElementById('retailers_map');
                    maCarte = MapsProviderService.initSearchShopPage(mapHtml);
                } else {
                    maCarte = new google.maps.Map(document.getElementById("retailers_map"), myOptions);
                }

                clusterer = new MarkerClusterer(maCarte, markers, mcOptions);

                boundChanched(maCarte, markersArray);
                markers = [];
            }
            
            //We center the map on France
            if (document.getElementById('search_pays').value === 'France' && document.getElementById('search_cp').value === '') {
                var latlng = new google.maps.LatLng(46.864716, 2.2);
                maCarte.setZoom(5);
                maCarte.setCenter(latlng);
                
            } else {
                // We center depending on the markers found
                maCarte.setCenter(rectangleMarqueurs.getCenter(), 
                maCarte.fitBounds(rectangleMarqueurs));
                
               // adjust zoom lvl on geoloc 
                $('#btn_geoloc').on('click tap touch', function () {
                    setTimeout( () => {
                        maCarte.setZoom(10);
                    }, 1000);
                });
                
                //adjust zoom lvl on search with result
                $(document).ready(function() {
                    if ($('.loc_input.pac-target-input').val().trim() !== '') {
                        setTimeout(() => {
                            maCarte.setZoom(10);
                        }, 1000);
                    }
                });
            }

            if (marqueurs.length === 1) {
                maCarte.setZoom(16);
            }
        }
        displayLoader('show');
    });
}