function loadProduct(type_filter, id, type_page, itm_rr_id) {
    var tmp_qte = $("#qteProd_" + id).val();
    var is_wishlist = false;
    var is_achat_express_v2 =
        this.form !== undefined &&
        this.form.elements["achatExpressV2"] !== undefined &&
        !is_wishlist;

    if (typeof id == "undefined") {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof type_page == "undefined") {
        type_page = "product";
    }

    var $form = "";
    var data = "";

    if (!is_achat_express_v2) {
        $form = $("#item_add_form");
        var $input = $("#prod_" + type_filter + id_prod, $form);
        var type = type_filter;
        var $hidden = $("#prod_" + type_filter + id_prod, $form);

        if (typeof id == "undefined") {
            data += $form.serialize();
        }

        var couleur_id = 0;

        couleur_id = $("#prod_couleur input[type=radio]:checked").val();
        $produit_id_new = $("#prod_" + type_filter + id_prod)
            .find(":selected")
            .attr("data-produitid");
        if ($produit_id_new == undefined) {
            $produit_id_new = $("#prod_" + type_filter + id_prod)
                .find(":checked")
                .attr("data-produitid");
        }

        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=" + $("#prod_taille").val();
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;
        if (document.getElementById("wishlist")) {
            data += "&type_page=" + type_page;
        }

        if (typeof id != "undefined") {
            data += "&couleurProd=" + $("#couleurProd" + id_prod).val();
            data += "&tailleProd=" + $("#tailleProd" + id_prod).val();
            data +=
                "&produit_principal=" + $("#produit_principal" + id_prod).val();
            var produit_principal = $("#produit_principal" + id_prod).val();
        }

        data += "&produit_id_new=" + $produit_id_new;
        var elemClass = $(".reloadProduct")
            ? ".reloadProduct"
            : ".swiper-slide"; //Selection of the class on the vertical_product for reloading the products (Master = 'reloadProduct', Override = 'swiper-slide') e.g., Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass);
    } else {
        var elemClass = $(this).parents(".reloadProduct").length
            ? ".reloadProduct"
            : ".swiper-slide"; //Selection of the class on the vertical_product for reloading the products (Master = 'reloadProduct', Override = 'swiper-slide') e.g., Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass)
        var swiper_achat_express_v2 = $(this).parents(".swiper-container");

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements["produit_id"].value;
        var isCategSuper = this.form.elements["isCategSuper"].value;
        var new_id = id;
        itm_rr_id = itm_rr_id !== "" ? "_" + itm_rr_id.split("_")[1] : "";
        var cpt_prod = itm_rr_id !== "" ? itm_rr_id.replace("_", "") : "";

        var couleur_id = 0;
        couleur_id = $(this).val();

        data += "produit_id=" + old_id;
        data += "&produit_principal=" + old_id;
        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=";
        data += "&qteProd=1";
        data += "&hdn_product_id=" + old_id;
        data += "&produit_id_new=" + new_id;
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;
        data += "&is_achat_express=0";
        data += "&is_achat_express_v2=1";
        data += "&cpt_prod=" + (cpt_prod - 1);
        data += "&isCategSuper=" + isCategSuper;
    }

    if (typeof GEM_Services != "undefined") {
        data +=
            "&warehouse_only=" +
            (GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings
                .IsOperatedByGlobalE
                ? "true"
                : "false");
    }

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_get_product_regroup_ref.php",
        data: data,
        beforeSend: function () {
            $("#bloc_btn_loader").show();
            $("#btn_error").fadeOut(300);
        },
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (response.success == "ok") {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        event: "view_item",
                    });
                }

                var datas = response.datas;

                if (type_page == "product") {
                    var $html_main = $(datas.html_main);

                    if (is_achat_express_v2) {
                        // Photo and information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if (
                            $("#oneProduct") != undefined &&
                            $("#oneProduct").hasClass("actif")
                        ) {
                            $(".itm_achat_express_" + new_id).addClass("full");
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof four_images != "undefined") {
                                enableSwiperFourImage();
                            } else {
                                if(!$(("body.product_page .gondole_derniers_articles_vus").length)) {
                                    var id_swiper = swiper_achat_express_v2.attr("id");
                                    refreshSliderAchatExpress( id_swiper );
                                }
                            }
                        }
                        
                        $("#shade")
                            .removeClass("isExpress")
                            .removeClass( "visible" );
                        if ($(".full.btnAddBasketWrapper.noSizeAvailable").length > 0) {
                            $( ".full.btnAddBasketWrapper.noSizeAvailable" ).append( `<div class="lightbox_buttons">
                                    <div class="form_submit">
                                        <button class="button cancel" onclick="PopUpNotAvailable();">
                                            <span>Fermer</span>
                                        </button>
                                    </div>
                                </div>` );
                        }
                        setTimeout(function () {
                            initItemSlider(false);
                        }, 200);
                    } else {
                        var $fiche = $("#contents_wrapper");
                        var replace_main = new Array(
                            "#wrapper_product_name",
                            "#zoom_container",
                            ".prod_page_bot",
                            ".associations_section",
                            ".accordion_container",
                            ".product_infos .price_container",
                            ".cms-page-module",
                            "w-netreviews-stars",
                            "w-netreviews-comments",
                            ".netreviews_reviews",
                            ".accordion_item #productRef",
                            "#item_carac",
                            "#item_description",
                            "#traceBox",
                            "#list_fp_characts",
                        );

                        var product_refext_prod = $(
                            "input[name=itm_refext_prod]",
                            $html_main
                        ).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == "w-netreviews-stars") {
                                // New Verified reviews
                                $("#w-netreviews-stars", $fiche).html(
                                    $("w-netreviews-stars", $html_main).html()
                                );
                                // Reload vuejs Verified reviews
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsStars
                                ) {
                                    // Verified reviews stars
                                    if (
                                        wShop.$refs.wNetreviewsStars !=
                                        "undefined"
                                    ) {
                                        wShop.$refs.wNetreviewsStars.reload(
                                            product_refext_prod
                                        );
                                    }
                                }
                            } else if (id == "w-netreviews-comments") {
                                // New Verified reviews
                                $("#w-netreviews-comments", $fiche).replaceWith(
                                    $("w-netreviews-comments", $html_main)
                                        .wrap("<p/>")
                                        .parent()
                                        .html()
                                );
                                // Reload vuejs Verified reviews
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsComments
                                ) {
                                    // Verified reviews
                                    if (
                                        wShop.$refs.wNetreviewsComments !=
                                        "undefined"
                                    ) {
                                        wShop.$refs.wNetreviewsComments.reload(
                                            product_refext_prod
                                        );
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html(
                                        $(id, $html_main).html()
                                    );
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        if(!$('body').hasClass('product_page')) {
                                            $(id, $fiche).replaceWith("");
                                        }
                                    }
                                }

                                /******* OLD Verified reviews ************/
                                if (id == ".netreviews_reviews") {
                                    if (
                                        $("#avis_verifie_produit").length ||
                                        $(".netreviews_reviews").length ||
                                        $(".netreviews_stars").length
                                    ) {
                                        if (
                                            typeof netreviews_product_url !=
                                            "undefined" &&
                                            netreviews_product_url !== null
                                        ) {
                                            // netreviews_product_url defined in views_mobile_new/main.php
                                            (function () {
                                                var script =
                                                    document.createElement(
                                                        "script"
                                                    );
                                                script.setAttribute(
                                                    "id",
                                                    "ajax_verified"
                                                );
                                                script.setAttribute(
                                                    "type",
                                                    "text/javascript"
                                                );
                                                script.setAttribute(
                                                    "src",
                                                    netreviews_product_url
                                                );

                                                // If ajax already being done, verify if script exists before adding it to HEAD
                                                if (
                                                    document.getElementById(
                                                        "ajax_verified"
                                                    ) !== null
                                                ) {
                                                    document
                                                        .querySelector(
                                                            "#ajax_verified"
                                                        )
                                                        .remove();
                                                }

                                                document
                                                    .getElementsByTagName(
                                                        "head"
                                                    )[0]
                                                    .appendChild(script);
                                            })();
                                        }
                                    }

                                    if (
                                        typeof nrSearchForStars !==
                                        "undefined" &&
                                        $(".NETREVIEWS_PRODUCT_STARS").length
                                    ) {
                                        nrSearchForStars();
                                    }

                                    // Display avis verifie comments
                                    // Call nrSearchForReviews from tag.min.js avis verifie NETREVIEWS_PRODUCT_URL
                                    if (
                                        typeof nrSearchForReviews !==
                                        "undefined" &&
                                        $(".NETREVIEWS_PRODUCT_REVIEWS").length
                                    ) {
                                        nrSearchForReviews();
                                        setTimeout(function () {
                                            if (
                                                $(
                                                    "#netreviews_reviews_tab"
                                                ).height() === undefined
                                            ) {
                                                $("#avis_verifies").hide();
                                            } else {
                                                $("#avis_verifies").show();
                                            }
                                        }, 1500);
                                    }
                                }
                            }
                        }

                        /******* ASSOCIATED PRODUCTS ************************************/
                        /***************************************************************/

                        var slides_per_view =
                            typeof associatedProduct_spv != "undefined"
                                ? associatedProduct_spv
                                : 3;
                        var asso_btn_prev =
                            typeof assoBtnPrev != "undefined"
                                ? assoBtnPrev
                                : ".swiper-button-prev";
                        var asso_btn_next =
                            typeof assoBtnNext != "undefined"
                                ? assoBtnNext
                                : ".swiper-button-next";
                        var asso_space_between =
                            typeof assoSpaceBetween != "undefined"
                                ? assoSpaceBetween
                                : 20;
                        var asso_swiper_params =
                            typeof assoSwiperParams != "undefined"
                                ? assoSwiperParams
                                : {
                                    slidesPerView: slides_per_view,
                                    spaceBetween: asso_space_between,
                                    nextButton: asso_btn_prev,
                                    prevButton: asso_btn_next,
                                };

                        // Associations_section = tabs (=> onglets)
                        if ($(".associations_section", $html_main).length > 0) {
                            // Check if associated products are displayed as tabs
                            if (
                                $(
                                    ".product_associations_wrapper",
                                    $html_main
                                ).attr("data-tab") !== undefined
                            ) {
                                // First check if there's an active class on each titles and wrappers
                                if (!$(".association_title.active").length) {
                                    $(".association_title")
                                        .first()
                                        .addClass("active");
                                }

                                if (
                                    !$(".product_associations_wrapper.active")
                                        .length
                                ) {
                                    $(".product_associations_wrapper")
                                        .first()
                                        .addClass("active");

                                    // Init also swiper on page load
                                    if (
                                        $(
                                            ".product_associations_wrapper.active .swiper-container .swiper-slide"
                                        ).length >
                                        Math.floor(
                                            asso_swiper_params.slidesPerView
                                        )
                                    ) {
                                        new Swiper(
                                            ".product_associations_wrapper.active .swiper-container",
                                            asso_swiper_params
                                        );
                                    }
                                }

                                // Once first title and first swiper is active, handle click on each tabs dynamically
                                $(".association_title").on(
                                    "click",
                                    function () {
                                        var title_tab =
                                            $(this).attr("data-tab");
                                        var active_tab = $(
                                            ".product_associations_wrapper.active"
                                        );

                                        // If the clicked title is not active yet, set it to active and remove active class from the last title
                                        if (!$(this).hasClass("active")) {
                                            $(
                                                ".association_title.active"
                                            ).removeClass("active");
                                            $(this).addClass("active");
                                        }

                                        // If new active title, display matching associations tab
                                        if (
                                            title_tab !=
                                            active_tab.attr("data-tab")
                                        ) {
                                            active_tab.removeClass("active");
                                            $(
                                                '.product_associations_wrapper[data-tab="' +
                                                title_tab +
                                                '"]'
                                            ).addClass("active");

                                            // Swiper activation
                                            if (
                                                $(
                                                    ".swiper-container",
                                                    '.product_associations_wrapper[data-tab="' +
                                                    title_tab +
                                                    '"]'
                                                )
                                            ) {
                                                // If more than slides_per_view, we activate the swiper
                                                if (
                                                    $(
                                                        '.product_associations_wrapper[data-tab="' +
                                                        title_tab +
                                                        '"] .swiper-slide'
                                                    ).length >
                                                    Math.floor(
                                                        asso_swiper_params.slidesPerView
                                                    )
                                                ) {
                                                    setTimeout(
                                                        new Swiper(
                                                            '.product_associations_wrapper[data-tab="' +
                                                            title_tab +
                                                            '"] .swiper-container',
                                                            asso_swiper_params
                                                        ),
                                                        500
                                                    );
                                                }
                                            }
                                        }
                                    }
                                );
                            }
                        } else {
                            // Init product_associations_wrapper swiper(s)
                            if ($(".product_associations_wrapper").length > 1) {
                                $(".product_associations_wrapper").each(
                                    function () {
                                        if (
                                            $(this).find(".swiper-slide")
                                                .length >=
                                            Math.floor(
                                                asso_swiper_params.slidesPerView
                                            )
                                        ) {
                                            new Swiper(
                                                $(this).find(
                                                    ".swiper-container"
                                                ),
                                                asso_swiper_params
                                            );
                                        }
                                    }
                                );
                            } else if (
                                $(".product_associations_wrapper").length > 0
                            ) {
                                if (
                                    $(".product_associations_wrapper").find(
                                        ".swiper-slide"
                                    ).length >=
                                    Math.floor(asso_swiper_params.slidesPerView)
                                ) {
                                    new Swiper(
                                        ".product_associations_wrapper .swiper-container",
                                        asso_swiper_params
                                    );
                                }
                            }
                        }

                        // Get already selected size and apply it when color is changed
                        const getSavedState = sessionStorage.getItem('radioId');
                        if (getSavedState !== null && getSavedState !== '') {
                            const radioButton = $('#' + getSavedState);

                            if (radioButton.length) {
                                radioButton.click();
                            }
                        }

                        // If there's not an associations_section working with a tabulation mechanism -> check carousel_wrapper
                        if (!$(".associations_section", $html_main).length) {
                            if ($("#carousel_wrapper", $html_main).length > 0) {
                                // For sites using the _app view, some sites don't want one particular swipers on reload so remove it from dom
                                if (typeof carouselException != "undefined") {
                                    var new_carousel = $(
                                        "#carousel_wrapper",
                                        $html_main
                                    );

                                    // Removing carousel exception from html_main response
                                    if (
                                        $(
                                            ".ranges_carousel_wrapper." +
                                            carouselException,
                                            new_carousel
                                        ).length
                                    ) {
                                        $(
                                            ".ranges_carousel_wrapper." +
                                            carouselException,
                                            new_carousel
                                        ).remove();
                                    }

                                    $("#carousel_wrapper").html(
                                        new_carousel.html()
                                    );
                                } else {
                                    $("#carousel_wrapper").html(
                                        $(
                                            "#carousel_wrapper",
                                            $html_main
                                        ).html()
                                    );
                                }

                                // Check if site has declared several associated swipers for its FP
                                if (
                                    typeof oAssociatedSwipers != "undefined" &&
                                    typeof oAssociatedSwipers == "object"
                                ) {
                                    // Loop and init swipers
                                    $.each(
                                        oAssociatedSwipers,
                                        function (container, params) {
                                            // Check if there are enough slides to init swiper
                                            if ($(container).length > 0) {
                                                if (
                                                    $(container).find(
                                                        ".swiper-slide"
                                                    ).length >=
                                                    params.slidesPerView
                                                ) {
                                                    // Check if swiper-container is a sibling or a child
                                                    setTimeout(
                                                        new Swiper(
                                                            $(container),
                                                            params
                                                        ),
                                                        500
                                                    );
                                                }
                                            }
                                        }
                                    );
                                } else {
                                    // If swipers are identical, init them the classic way
                                    if (
                                        $("#carousel_wrapper .swiper-container")
                                            .length > 1
                                    ) {
                                        $(
                                            "#carousel_wrapper .swiper-container"
                                        ).each(function () {
                                            if (
                                                $(this).find(".swiper-slide")
                                                    .length >=
                                                asso_swiper_params.slidesPerView
                                            ) {
                                                setTimeout(
                                                    new Swiper(
                                                        $(this),
                                                        asso_swiper_params
                                                    ),
                                                    500
                                                );
                                            }
                                        });
                                    } else if (
                                        $("#carousel_wrapper .swiper-container")
                                            .length > 0
                                    ) {
                                        if (
                                            $(
                                                "#carousel_wrapper .swiper-container"
                                            ).find(".swiper-slide").length >=
                                            asso_swiper_params.slidesPerView
                                        ) {
                                            setTimeout(
                                                new Swiper(
                                                    "#carousel_wrapper .swiper-container",
                                                    asso_swiper_params
                                                ),
                                                500
                                            );
                                        }
                                    }
                                }
                            } else {
                                $("#carousel_wrapper").html("");
                            }
                        }
                        if ($("#publication_page", $html_main).length > 0) {
                            $("#publication_page").html(
                                $("#publication_page", $html_main).html()
                            );
                        } else {
                            $("#publication_page").html("");
                        }

                        window.prod_slider = document.getElementById(
                            "product_pictures_slider"
                        );
                        window.asso_slider = document.getElementById(
                            "asso_product_carousel"
                        );

                        if (
                            asso_slider &&
                            $(".swiper-slide", asso_slider).length > 0 &&
                            typeof assoSwiperParams == "undefined"
                        ) {
                            var assoSwiperSlider = new Swiper(
                                ".swiper-container.asso_product_carousel",
                                {
                                    slidesPerView: 3,
                                    centeredSlides: true,
                                    paginationClickable: true,
                                    spaceBetween: 20,
                                    pagination: ".swiper-pagination",
                                    preloadImages: true,
                                    lazyLoading: true,
                                    loop: true,
                                    nextButton: ".swiper-button-next",
                                    prevButton: ".swiper-button-prev",
                                }
                            );
                        }

                        /* Special case for a single view slider */
                        if (
                            $(".assocSlider").length > 0 &&
                            $(".assocSlider .swiper-slide").length >= 1 &&
                            typeof assoSwiperParams == "undefined"
                        ) {
                            var assoSlider = new Swiper(".assoSlider", {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: ".swiper-button-next",
                                prevButton: ".swiper-button-prev",
                            });
                        }

                        var string_params = "";
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params +=
                                "&produit_lot_nb=" + $("#produit_lot_nb").val();
                        }

                        if ($("#tailleProd").val() > 0) {
                            // Reload quantity 
                            var qte = ajax_file(
                                path_relative_root +
                                "ajax_liste_qte.php?idprod=" +
                                datas.product.id +
                                "&idcouleur=" +
                                datas.product.color.id +
                                "&idtaille=" +
                                $("#tailleProd").val() +
                                string_params
                            );
                            $("#liste_qte").html(qte);
                        }

                        // If we have one size we directly check the stock of the product (cf VB)
                        if (
                            $('input[name="itm_one_size"]').length &&
                            $('input[name="itm_one_size"]').val() === "1"
                        ) {
                            onAttrSelect(parseFloat(datas.product.id));
                        }

                        var contentMovable = $("#content_movable");

                        $(".jqzoom").on("doubletap", function () {
                            zoomImg.call(this);
                            $("#zoomBox").addClass("active");

                            contentMovable.addClass("visible");
                            var zoomboxWidth = $("body").width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $("#zoomBox").scrollLeft(scrollTo);
                            }, "slow");
                        });

                        contentMovable.on("doubletap", function () {
                            $(this).removeClass("visible");
                            $("#zoomBox .btn_close").trigger("click");
                        });

                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        $.each(stocks, function (index, stock) {
                            $.each(stock, function (key, val) {
                                if (val === true) {
                                    hasStock = true;
                                    $("#size_" + key).removeClass("disabled");
                                } else {
                                    $("#size_" + key).addClass("disabled");
                                }
                            });
                        });

                        // if we have stock, we display the corresponding elements
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }

                            if ($("#button_add") !== undefined) {
                                $("#button_add").show();
                            }

                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                            // otherwise, we hide them
                        } else {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }

                            if ($("#button_add") !== undefined) {
                                $("#button_add").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").show();
                            }
                        }
                    }
                    if (
                        $("#oneProduct").length &&
                        $("#oneProduct").hasClass("actif")
                    ) {
                        $("div.item_container").addClass("full");
                    }
                } else if (type_page == "look") {
                    var $html_main = $(datas.main_content);

                    $("#ref_look_" + produit_principal).html(
                        $("#ref_look_" + produit_principal, $html_main).html()
                    );
                    $("#look_add_lightbox_" + produit_principal).html(
                        $(
                            "#look_add_lightbox_" + produit_principal,
                            $html_main
                        ).html()
                    );

                    calculTotal("slide_look_detail");

                    var $id_current = id_prod.substring(
                        id_prod.lastIndexOf("_") + 1
                    );
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Once the information is loaded, we will update the quantity select with the newly chosen product
                    showQteListeGamme(
                        "liste_qte_" + datas.product.id,
                        datas.product.id,
                        path_relative_root
                    );
                } else if (type_page == "assoc") {
                    var $html_main = $(datas.html_main);
                    $("#ref_assoc_" + produit_principal).html(
                        $("#ref_assoc_" + produit_principal, $html_main).html()
                    );

                    // Once the information is loaded, we will update the quantity select with the newly chosen product
                    showQteListeGamme(
                        "liste_qte_" + datas.product.id,
                        datas.product.id,
                        path_relative_root
                    );
                }

                if (
                    $("#btn_add_alert", response.datas.html_main) &&
                    $("#btn_add_alert", response.datas.html_main).attr(
                        "onclick"
                    ) &&
                    $("#btn_add_alert", response.datas.html_main)
                        .attr("onclick")
                        .indexOf("lightboxAlertStock") !== false
                ) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }
            } else {
                $("#btn_error").fadeIn();
            }

            if (document.getElementById("alma-widget") !== null) {
                initAlmaWidget();
            }

            // Create CustomEvent "loadProduct_success" to succes LoadProduct
            var evt = new CustomEvent("loadProduct_success");
            window.dispatchEvent(evt);
            
            // By default show 'sélectionner une taille' btn if no size is chosen 
            if ($('.product_fields.size_filter .form_itm.check.size input[type="radio"]:checked').length > 0) {
                // At least one radio button is checked
                $('#button_add span').text(Translator.translate('add_basket'));
                $('#button_add').removeClass('noSize');
                $('#button_add').css('opacity', '1');
                
            } else {
                // No radio buttons are checked
                $('#button_add span').text(Translator.translate('select_size'));
                $('#button_add').addClass('noSize');
            }
        },
        complete: function () {
            $("#bloc_btn_loader").fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            var form = document.getElementById("item_add_form");

            if (is_achat_express_v2) {
                form = $form;
            } else {
                colors = form.itm_colour;
                color = colors ? colors.value : "none";
                color_checked = color != "none" ? $("#color_" + color) : "";
                updateColor.call(color_checked);
            }

            if (!data.is_achat_express_v2) {
                checkProductInWishlist();
            }

            setTimeout(function () {
                $(".zoom_indicator").fadeOut(800);
            }, 2500);

            if (type_page == "product") {
                $("#ctaAvailabilityInStoreTitle").html(Translator.translate('availabilityInStore'));
            }

            // Create CustomEvent "loadProduct_complete" to complete LoadProduct
            var evt = new CustomEvent("loadProduct_complete");
            window.dispatchEvent(evt);

            // WPOS Exclusive - Get if product is available in store use in WPOS
            var idStore = getCookie('wshopStoreId');
            var idProduct = getCookie('p_id');
            if (idStore != undefined && idStore > 0) {
                var color = $("#prod_couleur").val();
                var size = $("#prod_taille").val();
                getProductInfoByStore(idProduct, color, size, idStore)
            }

            // FP Association
            if ($('body').hasClass('product_page')) {
                $("#carousel_wrapper .reloadProduct").each(function() {
                    if (!$(this).hasClass('swiper-slide')) {
                        $(".reloadProduct").addClass('swiper-slide');
                    }
                });
            }

            // Trigger a loadNewImageCompleted event on successful completion
            $('body.product_page #carousel_wrapper').trigger('loadNewImageCompleted');

            setTimeout(() => {
                initItemSlider();
            }, 10);
        },
    });
}

// Updates visual and thumbnail for selected color
function updateColor() {
    var item_visu = document.getElementById("img_large");
    var item_url = this.attr("data-itm-img");
    var colr_url = path_relative_root + "img/couleur/" + this.value + ".jpg";
    var colr_hexa = this.attr("data-hexa");
    var is_model = this.attr("data-ismodel");
    var has_photo = this.attr("data-color-img");

    if (has_photo == "0") {
        has_photo = false;
    }

    if (this.value != "") {
        // target and display images for each selected color
        if ($("#img_large.product_picture").length > 0) {
            $("#img_large.product_picture").each(function (index) {
                $(this).attr("src", item_url);
            });
        } else {
            item_visu.src = item_url;
        }
    }
            
    // By default show 'sélectionner une taille' btn if no size is chosen 
    if ($('.product_fields.size_filter .form_itm.check.size input[type="radio"]:checked').length > 0) {
        // At least one radio button is checked
        $('#button_add span').text(Translator.translate('add_basket'));
        $('#button_add').removeClass('noSize');
        $('#button_add').css('opacity', '1');
        
    } else {
        // No radio buttons are checked
        $('#button_add span').text(Translator.translate('select_size'));
        $('#button_add').addClass('noSize');
    }
}

// Triggers on attribute alteration
function onAttrSelect(idProduit) {
    var form = document.getElementById("item_add_form"),
        colors = form.itm_colour,
        sizes = form.itm_size,
        quantities = form.itm_quantity,
        color,
        size;

    if (this.value) {
        this.classList.remove("error");
    }

    color = colors ? colors.value : "none";
    color_checked = color != "none" ? $("#color_" + color) : "";
    if (color_checked) {
        idProduit =
            color_checked.attr("data-produit_id") != ""
                ? color_checked.attr("data-produit_id")
                : idProduit;
    }

    if (color !== "none") {
        updateColor.call(color_checked);
        if (this !== window && this.classList.contains("color")) {
            setAvailableSizes.call(this);
        }
    }

    if (color == "") {
        if ($('input[name="firstColorStock"]') !== undefined) {
            var color = $('input[name="firstColorStock"]').val();
            if (color) {
                $('#prod_couleur option[value="' + color + '"]').prop(
                    "selected",
                    true
                );
            }
        }
    }

    size = sizes ? sizes.value : "none";
    size_checked = size != "none" ? $("#size_" + size) : "";

    if ($("#prod_taille").length > 0) {
        $("#prod_taille").val(size);
    }

    if (sizes !== undefined) {
        var nbsize = sizes.length === undefined ? 0 : sizes.length;
        var sizeValue =
            size && size_checked !== undefined
                ? size_checked.attr("data-nom")
                : 0;

        if (size && nbsize > 1 && sizeValue !== undefined) {
            // DataLayer MAJ
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    event: "changeSize",
                    produit: {
                        size_value: sizeValue,
                    },
                });
                if (window.dataLayer[0].product) {
                    window.dataLayer[0].product.size = $(this).attr("data-nom");
                }
            }
        }
    }

    if (quantities) {
        $.ajax({
            url: path_relative_root + "ajax_liste_qte.php",
            type: "get",
            async: false, // Should be async but would then need a loader so...
            dataType: "json",
            data: {
                idprod: idProduit,
                idcouleur: color,
                idtaille: size,
                json: "1",
            },
            success: function (res) {
                var lastPiecesEclatBlock = $(
                    "#content_product #lastPiecesEclat"
                );
                var noStockEclat = $("#zoom_container .noStockEclat");
                /*
                    It can be useful when you need to display an eclat to let the customer know that the product isn't available at the moment:
                    - If the eclat exists, add a class depending on the availabality of the product for the selected size
                    - If my class is present on the element, I can display my eclat using style on the front office
                    (be sure to add your eclat in the view file depending on the project you're working on)
                */
                var nostock = false;

                if (res.inventory.enableAddToCart == true) {
                    if (
                        (typeof GEP != "undefined" &&
                            GEP.isOperatedByGlobale !== undefined &&
                            GEP.isOperatedByGlobale !== false) ||
                        (typeof GEP == "undefined" &&
                            typeof res.is_sfs !== "undefined" &&
                            res.is_sfs == false)
                    ) {
                        res.stock_tampon = 0;
                        nostock = true;
                    }
                }

                // If no_stock eclat exists
                if (noStockEclat.length > 0 || nostock) {
                    if (size != "") {
                        // If size is not empty == a real size has been selected in the FP select box
                        if (parseInt(res.stock_tampon) == 0) {
                            // If theres no stock on this size and the item hasn't the no_stock class yet
                            if (
                                !$("#zoom_container").hasClass("size_no_stock")
                            ) {
                                $("#zoom_container").addClass("size_no_stock");
                            }
                        } else {
                            // If there's stock but the no_stock class was already added from a previous size selection
                            if (
                                $("#zoom_container").hasClass("size_no_stock")
                            ) {
                                $("#zoom_container").removeClass(
                                    "size_no_stock"
                                );
                            }
                        }
                    } else {
                        // If the user hasn't selected a real size but the no_stock class was already added from a previous size selection
                        if ($("#zoom_container").hasClass("size_no_stock")) {
                            $("#zoom_container").removeClass("size_no_stock");
                        }
                    }
                }

                // If last pieces eclat exists
                if (
                    lastPiecesEclatBlock.length > 0 &&
                    alerte_last_stock != undefined &&
                    !nostock
                ) {
                    if (
                        parseInt(res.stock_tampon) <=
                        parseInt(alerte_last_stock) &&
                        parseInt(res.stock_tampon) !== 0
                    ) {
                        lastPiecesEclatBlock.addClass("is-visible");
                    } else {
                        lastPiecesEclatBlock.removeClass("is-visible");
                    }
                }

                var content = res.content;
                quantities.parentElement.innerHTML = content.trim();
                if (size != "") {
                    result = $($("#qteProd option:selected"), content);
                    if ($("#bloc_add_alert").length > 0) {
                        if (
                            $("#bloc_another_basket").length > 0 &&
                            res.stock_quantite > 0 &&
                            res.stock_tampon == 0
                        ) {
                            $("#bloc_add_alert").hide();
                            $("#add_basket_wrapper").hide();
                            $(".stock_check").hide();
                        } else {
                            if (result.hasClass("no_stock") || nostock) {
                                $("#bloc_add_alert").show();
                                $("#add_basket_wrapper").hide();
                                $(".stock_check").hide();
                            } else {
                                if (
                                    typeof use_preorder_with_alert ==
                                    "undefined" ||
                                    (typeof use_preorder_with_alert !=
                                        "undefined" &&
                                        res.produit_precommande != "oui")
                                ) {
                                    $("#bloc_add_alert").hide();
                                }
                                $("#add_basket_wrapper").show();
                                $(".stock_check").show();
                            }
                        }
                    } else {
                        if (result.hasClass("no_stock") || nostock) {
                            onAttrDisableAddCart();
                        } else {
                            onAttrEnableAddCart();
                        }
                    }
                }

                checkProductInWishlist();

                // Check if globale delivery country
                // your logic should be added here
                if (res.inventory.enableAddToCart == true) {
                    if (
                        typeof GEM_Services != "undefined" &&
                        typeof GEM_Services.ConfigurationsService.Instance
                            .LocalizeConfigurations != "undefined"
                    ) {
                        var userCountry =
                            GEM_Services.ConfigurationsService.Instance
                                .LocalizeConfigurations.UserCountry;
                    }

                    //respectively VB and JONAK condition
                    if (
                        (typeof userCountry !== "undefined" &&
                            userCountry.IsOperatedByGlobalE == true) ||
                        ((typeof userCountry === "undefined" ||
                                (typeof userCountry !== "undefined" &&
                                    userCountry.IsOperatedByGlobalE == false)) &&
                            typeof res.is_sfs !== "undefined" &&
                            res.is_sfs == false)
                    ) {
                        $("#add_basket_wrapper").hide();
                        $(".bloc_add_alert").show();
                    }

                    if (
                        typeof res.is_personalize !== "undefined" &&
                        res.is_personalize == true
                    ) {
                        $(".btn_personalize").hide();
                    } else {
                        $(".btn_personalize").show();
                    }
                }
            },
            complete: function () {
                // By default show 'sélectionner une taille' btn if no size is chosen 
                if ($('.product_fields.size_filter .form_itm.check.size input[type="radio"]:checked').length > 0) {
                    // At least one radio button is checked
                    $('#button_add span').text(Translator.translate('add_basket'));
                    $('#button_add').removeClass('noSize');
                    $('#button_add').css('opacity', '1');
                    
                } else {
                    // No radio buttons are checked
                    $('#button_add span').text(Translator.translate('select_size'));
                    $('#button_add').addClass('noSize');
                }
            }
        });
    }
    var idStore = getCookie('wshopStoreId');
    if (idStore != undefined && idStore > 0) {
        getProductInfoByStore(idProduit, color, size, idStore)
    }
}

$(function () {
    // By default show 'sélectionner une taille' btn if no size is chosen 
    if ($('.product_fields.size_filter .form_itm.check.size input[type="radio"]:checked').length > 0) {
        // At least one radio button is checked
        $('#button_add span').text(Translator.translate('add_basket'));
        $('#button_add').removeClass('noSize');
        $('#button_add').css('opacity', '1');
        
    } else {
        // No radio buttons are checked
        $('#button_add span').text(Translator.translate('select_size'));
        $('#button_add').addClass('noSize');
    }

    //FP Eclat nouveauté and epuisé
    $('body.product_page .eclat_epuise.eclat_spe, body.product_page .eclat_nouveaute.eclat_spe').appendTo('#zoom_container');
});

function openSizeAchatExpressV2() {
    var form = this.form;
    var $div_color = $(form).find('.productColorFieldset');
    var $div_size = $(form).find('.productSizeFieldset');
    var size_id = $(form).find('input[name="tailleProd"]').val();
    var $div_alert_stock = $(form).find('.bloc_add_alert');
    var $div_size_not_available = $(form).find('.noSizeAvailable');
    $div_alert_stock.hide();
    $div_size_not_available.hide();
    var lightbox = $(this.form).find('.achat_express');
    var lightBoxColors = lightbox.hasClass('displayColors');

    if (lightBoxColors) {
        lightbox.removeClass('displayColors');
    }

    if (size_id !== 'none') {
        form.elements['itm_size'].checked = false;
    }

    $("#shade").addClass("isExpress").addClass("visible");

    if ($div_size.hasClass('actif')) {
        $(form).find('.achat_express').hide();
        $div_size.removeClass('actif');
        
    } else {
        $div_color.removeClass('actif');
        
        if (size_id === 'none') {
            sendFormUpdateCart.call(form);
            
        } else {
            $(form).find('.achat_express').show();
            $div_size.addClass('actif');
        }
    }

    $('#shade.isExpress').off('click touch').on('click touch', function() {
        $(form).find('.achat_express').hide();
        $(this).removeClass('visible isExpress');
        $div_size.removeClass('actif');
    });

    if (isGlobalEActive) {
        $('.no_stock_entrepot').each(function() {
            if ($(this).html() == '') {
                if (!$(this).prev().hasClass('no_stock')) {
                    $(this).prev().addClass('no_stock');
                    $(this).prev().show();
                }
            } else {
                $(this).show();
            }
        });
    }
}

/* Achat Express Association TG popUp END */

function triggerProductDatalayerPush(prod_id, url) {
    if (typeof productlist_gondole !== 'undefined') {
        for (var i = 0; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id) {
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': list },      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}
